import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueResource from "vue-resource";

Vue.use(VueResource);

Vue.http.interceptors.push((request) => {
  return (response) => {
    if (response.status === 401) router.push({ name: "Login" });
  };
});

Vue.http.options.credentials = true;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
