<template>
  <qb-calls-viewer :customer-calls="customerCalls" :clear-call="clearCall" />
</template>

<script>
import QbCallsViewer from "../components/QbCallsViewer";

let refreshInterval = null;

export default {
  name: "QBCalls",
  data: () => {
    return {
      customerCalls: [],
    };
  },
  components: {
    QbCallsViewer,
  },
  mounted() {
    refreshInterval = setInterval(this.refreshCalls, 3000);
    this.refreshCalls();
  },
  unmounted() {
    if (refreshInterval) clearInterval(refreshInterval);
    return true
  },
  methods: {
    refreshCalls() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}qbcalls`)
        .then((response) => {
          this.customerCalls = response.body;
        })
        .catch(console.error);
    },
    clearCall(qbCall) {
      if (
        window.confirm(`Are you sure you want to clear call ${qbCall.uuid}?`)
      ) {
        this.$http
          .delete(`${process.env.VUE_APP_API_URL}qbcalls/${qbCall.uuid}`)
          .then((response) => {
            this.refreshCalls();
          })
          .catch(console.error);
      }
    },
  },
};
</script>
