<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-select v-model="selectedDomain" :items="domains" item-title="text" item-value="value"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="item in filteredConfigItems" :key="item.id || item.number" cols="4">
        <h3 class="text-center">
          {{ item.id || item.number }}
          {{
            item.config_json && item.config_json.name
              ? `(${item.config_json.name})`
              : ""
          }}
        </h3>
        <p class="text-center">{{ item.domain_name }}</p>
        <p class="text-center">
          <v-btn elevation="2" x-small @click="exportRecord(item)"
            >Export</v-btn
          >
        </p>
        <v-card class="pa-2" outlined tile>
          <json-viewer
            :value="item"
            :expand-depth="1"
            copyable
            boxed
            :sort="true"
          ></json-viewer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";

Vue.use(JsonViewer);

export default {
  props: ["configItems"],

  data() {
    return {
      selectedDomain: null,
    };
  },

  computed: {
    domains() {
      let domains = this.configItems
        .map((item) => item.domain_name)
        .reduce((acc, cur) => {
          if (!acc.includes(cur)) acc.push(cur);
          return acc;
        }, [null]);
      
      return domains.map(domain => {
        if (domain === null)
          return { text: 'All domains', value: null }
        else
          return {
            text: domain, value: domain
          }
      });
    },
    filteredConfigItems() {
      if (this.selectedDomain)
        return this.configItems?.filter(item => item.domain_name === this.selectedDomain)
      else
        return this.configItems
    },
  },

  methods: {
    exportRecord(record) {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(record, null, 2));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        `config-${record.id || record.number}.json`
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  },
};
</script>
