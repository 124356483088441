<template>
  <v-container>
    <span
      style="margin-bottom: 30px; padding-right: 20px"
      v-for="customer in customerCalls"
    >
      <a :href="'#' + customer.customer.display_name">{{
        customer.customer.display_name
      }}</a>
    </span>
    <div v-for="customer in customerCalls" :id="customer.customer.display_name">
      <v-row>
        <h1 style="padding: 20px">{{ customer.customer.display_name }}</h1>
      </v-row>
      <v-row>
        <v-col v-for="call in customer.calls" :key="call.colt_id" cols="4">
          <h3 class="text-center">{{ call.comment }}</h3>
          <p class="text-center">{{ formatDateTime(call.last_modified) }}</p>
          <p class="text-center">{{ call.call_id }} / {{ call.tag }}</p>
          <v-card class="pa-2" outlined tile>
            <json-viewer
              :value="call"
              :expand-depth="1"
              copyable
              boxed
              :sort="true"
            ></json-viewer>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";

Vue.use(JsonViewer);

export default {
  props: ["customerCalls"],

  methods: {
    formatDateTime(date) {
      if (date && new Date(date)) return new Date(date).toLocaleString();
      else return "";
    },
  },
};
</script>
