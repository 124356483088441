<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12 w-full">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @keyup.native.enter="login">
              <v-text-field
                autocomplete="username"
                v-model="username"
                prepend-icon="mdi-account"
                name="username"
                label="Username"
                type="text"
              ></v-text-field>
              <v-text-field
                autocomplete="current-password"
                v-model="password"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="message" style="color: red">{{
            message
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!username || !password || loggingIn"
              color="primary"
              @click="login()"
              >{{ loggingIn ? "Logging In..." : "Login" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      message: null,
      loggingIn: false,
    };
  },
  name: "Login",
  methods: {
    login() {
      this.loggingIn = true;

      this.$http
        .post(`${process.env.VUE_APP_API_URL}login`, {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.loggingIn = false;

          const data = response.body;
          localStorage.setItem(
            "user",
            JSON.stringify({ username: this.username, isAdmin: data.isAdmin })
          );
          this.$router.push({ name: "Home" });
        })
        .catch((e) => {
          this.loggingIn = false;
          console.log(`Error logging in`, e);

          if (e.status === 401) this.message = "Login Failed!";
          else this.message = "Error logging in";
        });
    },
  },
};
</script>

<style></style>
