<template>
  <v-app>
    <v-app-bar v-if="$route.name !== 'Login'" app clipped-left>
      <v-app-bar-nav-icon
        @click.stop="sidebarOpen = !sidebarOpen"
      ></v-app-bar-nav-icon>
      <img src="./assets/logo.png" class="bg-contain mx-auto h-6 sm:h-12" alt="FS Queue Manager">
      <div class="p-3 bg-[#EAEAEA] transition-all bg-opacity-0 cursor-pointer duration-500 hover:bg-opacity-100 rounded-full text-[#707070]" @click="navigateTo('/logout')">
          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#707070" d="m17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/></svg>
        </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$route.name !== 'Login'"
      v-model="sidebarOpen"
      app
      clipped
      class="z-20"
    >
      <v-list nav dense>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/fsevents">
          <v-list-item-icon>
            <v-icon>mdi-phone-log</v-icon>
          </v-list-item-icon>
          <v-list-item-title>FS Events</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/dbrecords">
          <v-list-item-icon>
            <v-icon>mdi-card-bulleted-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>DB Records</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/queuecalls">
          <v-list-item-icon>
            <v-icon>mdi-human-queue</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Queue Calls</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/qbcalls">
          <v-list-item-icon>
            <v-icon>mdi-account-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>QB Calls</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/agents">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Agents</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/accounts">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Accounts</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/ivrs">
          <v-list-item-icon>
            <v-icon>mdi-family-tree</v-icon>
          </v-list-item-icon>
          <v-list-item-title>IVRs</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/servicenumbers">
          <v-list-item-icon>
            <v-icon>mdi-phone-incoming</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Service Numbers</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/globalsettings">
          <v-list-item-icon>
            <v-icon>mdi-car-brake-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Global OOH / DR</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    sidebarOpen: true,
  }),
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
