<template>
  <div>
    <!-- Add a search box for a call ID -->
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-text-field
            type="text"
            label="Enter call ID"
            v-model="downloadId"
            variant="plain"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            class="ml-5 mt-3"
            elevation="2"
            color="primary"
            @click="downloadCall(false)"
            >View</v-btn
          >
          <v-btn
            elevation="2"
            class="ml-5 mt-3"
            color="primary"
            @click="downloadCall(true)"
            >Download</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <fs-event-viewer :calls="calls" :clear-call="clearCall" />
  </div>
</template>

<script>
import FsEventViewer from "../components/FsEventViewer";

let refreshInterval = null;

export default {
  name: "FSEvents",
  data: () => {
    return {
      calls: [],
      downloadId: "",
    };

  },
  components: {
    FsEventViewer,
  },
  mounted() {
    refreshInterval = setInterval(this.refreshCalls, 3000);
    this.refreshCalls();
  },
  unmounted() {
    if (refreshInterval) clearInterval(refreshInterval);
    return true
  },
  methods: {
    downloadCall(downloadAsFile) {
      if (this.downloadId) {
        if (downloadAsFile) {
          this.$http
            .get(`${process.env.VUE_APP_API_URL}fsevents/${this.downloadId}`)
            .then((response) => {
              const data = response.body;
              const blob = new Blob([JSON.stringify(data)], {
                type: "application/json",
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `call-${this.downloadId}-${new Date().toISOString()}.json`
              );
              document.body.appendChild(link);
              link.click();
              link.remove();
            })
            .catch(console.error);
        }
        else {
          window.open(
            `${process.env.VUE_APP_API_URL}fsevents/${this.downloadId}`,
            "_blank"
          )
        }
      }
    },
    clearCall(call) {
      this.$http
        .delete(`${process.env.VUE_APP_API_URL}fsevents/${call.call_id}`)
        .then((response) => {
          this.refreshCalls();
        })
        .catch(console.error);
    },
    refreshCalls() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}fsevents`)
        .then((response) => {
          const data = response.body;

          if (data) {
            let calls = [];
            Object.keys(data).forEach((c) => {
              calls.push({
                call_id: c,
                created_at: data[c].created_at,
                events: data[c].events,
              });
            });
            this.calls = calls.sort((a, b) =>
              a.created_at < b.created_at ? 1 : -1
            );
          }
        })
        .catch(console.error);
    },
  },
};
</script>
