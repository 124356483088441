<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-select v-model="selectedDomain" :items="domains" item-title="text" item-value="value"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="call in filteredCalls" :key="call.call_id" cols="4">
        <h3 class="text-center">Call ID {{ call.call_id }}</h3>
        <h3 class="text-center">{{ call && call.events && call.events.length ? call.events[0].domain_name : 'Unknown domain_name' }}</h3>
        <p class="text-center">{{ formatEpochDate(call.created_at) }}</p>
        <p class="text-center">
          <v-btn elevation="2" x-small @click="exportCall(call)">Export</v-btn>
          <v-btn elevation="2" color="red" x-small @click="clearCall(call)"
            >Clear</v-btn
          >
        </p>
        <v-card class="pa-2" outlined tile v-for="event in call.events">
          <v-card-subtitle
            >{{ event.event_name }}{{ event.count ? ` (${event.count})` : ""
            }}{{
              event.hangup_cause ? ` (${event.hangup_cause})` : ""
            }}</v-card-subtitle
          >
          <json-viewer
            :value="event"
            :expand-depth="0"
            copyable
            boxed
            sort
          ></json-viewer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";

Vue.use(JsonViewer);

export default {
  props: ["calls", "clearCall"],

  data() {
    return {
      selectedDomain: null,
    };
  },

  beforeMount() {
    this.calls = this.calls.map((call) => {
      call.events = this.removeUnrequiredProps(call.events);
      return call;
    });
  },

  computed: {
    filteredCalls() {
      if (this.selectedDomain)
        return this.calls.filter(call =>  call.events?.length && call.events[0].domain_name === this.selectedDomain)
      else
        return this.calls
    },
    domains() {
      let domains = this.calls
        .map((call) => call.events[0].domain_name)
        .reduce((acc, cur) => {
          if (!acc.includes(cur)) acc.push(cur);
          return acc;
        }, [null]);
      
      return domains.map(domain => {
        if (domain === null)
          return { text: 'All domains', value: null }
        else
          return {
            text: domain, value: domain
          }
      });
    },
  },

  methods: {
    removeUnrequiredProps(events) {
      events = events?.map((e) => {
        const {
          "Event-Calling-File": ecf,
          "Event-Calling-Function": ecfu,
          "Event-Calling-Line-Number": ecfl,
          "Event-Sequence": es,
          "Event-Name": en,
          "Event-Date-GMT": edg,
          "Event-Date-Timestamp": edt,
          "Event-Subclass": esc,
          "FreeSWITCH-Hostname": fsh,
          "FreeSWITCH-Switchname": fss,
          "FreeSWITCH-IPv4": fsi,
          "FreeSWITCH-IPv6": fsiv6,
          "Core-UUID": cu,
          "Job-UUID": ju,
          ...rest
        } = e;

        return rest;
      });

      return events;
    },
    formatEpochDate(epoch) {
      return new Date(parseInt(epoch)).toLocaleString();
    },
    exportCall(call) {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(call, null, 2));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", `call-${call.call_id}.json`);
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  },
};
</script>
