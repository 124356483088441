<template>
  <v-container>
    <v-row>
      <v-col cols="4" v-for="queueCall in customerCalls">
        <h3 class="text-center"><strong>Account: </strong>{{ lookupAccount(queueCall.account_id) }}</h3>
        <p class="text-center"><strong>Weighting:</strong> {{ Math.round(queueCall.weighting / 1000) }} ({{ queueSeconds(queueCall.queue_start_epoch) }} secs)</p>
        <v-card class="pa-2" outlined tile>
          <v-card-subtitle>{{ queueCall.caller_id_number || 'anonymous' }} -> {{ queueCall.service_number }}</v-card-subtitle>
          <json-viewer
            :value="queueCall"
            :expand-depth="1"
            :expanded="true"
            copyable
            boxed
            sort
          ></json-viewer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";

Vue.use(JsonViewer);

export default {
  props: ["customerCalls", "accounts"],

  methods: {
    formatEpochDate(epoch) {
      return new Date(parseInt(epoch) * 1000).toLocaleString();
    },
    lookupAccount(id) {
      let account = this.accounts?.find(
        (account) => account.id === id
      );

      if (account)
        return `${account.config_json.name} (${account.domain_name})`;
      else return id;
    },
    queueSeconds(epoch) {
      if (!parseInt(epoch)) return 'n/a'
      return Math.floor(Date.now() / 1000 - parseInt(epoch));
    },
  },
};
</script>
