import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import FSEventsView from "../views/FSEventsView.vue";
import DBRecordsView from "../views/DBRecordsView.vue";
import AccountsView from "../views/AccountsView.vue";
import ServiceNumbersView from "../views/ServiceNumbersView.vue";
import IVRsView from "../views/IVRsView.vue";
import GlobalSettingsView from "../views/GlobalSettingsView.vue";
import QBCallsView from "../views/QBCallsView.vue";
import QueueCallsView from "../views/QueueCallsView.vue";
import AgentsView from "../views/AgentsView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/fsevents",
    name: "FSEvents",
    component: FSEventsView,
  },
  {
    path: "/dbrecords",
    name: "DBRecords",
    component: DBRecordsView,
  },
  {
    path: "/qbcalls",
    name: "QB Calls",
    component: QBCallsView,
  },
  {
    path: "/queuecalls",
    name: "Queue Calls",
    component: QueueCallsView,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: AccountsView,
  },
  {
    path: "/agents",
    name: "Agents",
    component: AgentsView,
  },
  {
    path: "/ivrs",
    name: "IVRs",
    component: IVRsView,
  },
  {
    path: "/servicenumbers",
    name: "ServiceNumbers",
    component: ServiceNumbersView,
  },
  {
    path: "/globalsettings",
    name: "GlobalSettings",
    component: GlobalSettingsView,
  },
  {
    path: "/logout",
    name: "Logout",
    redirect: (_) => {
      localStorage.removeItem("user");
      Vue.http.post(process.env.VUE_APP_API_URL + "logout");
      return { path: "/login" };
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

console.log("API_URL", process.env.VUE_APP_API_URL);

router.beforeEach((to, from, next) => {
  let isAuthenticated = !!localStorage.getItem("user");
  if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
  else next();
});

export default router;
