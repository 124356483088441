<template>
  <v-container fluid>
    <DashboardItems />
    <CallsTable />
  </v-container>
</template>

<script>
import CallsTable from "@/components/CallsTable.vue";
import DashboardItems from "@/components/DashboardItems.vue";
export default {
  name: "Home",
  components: {
    CallsTable,
    DashboardItems,
  }
};
</script>
