<template>
  <config-object-viewer :config-items="configItems" />
</template>

<script>
import ConfigObjectViewer from "../components/ConfigObjectViewer";

export default {
  name: "ConfigObjects",
  data: () => {
    return {
      configItems: [],
    };
  },
  components: {
    ConfigObjectViewer,
  },
  mounted() {
    // this.refreshInterval = setInterval(this.refreshConfig, 3000);
    this.refreshConfig();
  },
  beforeUnmount() {
    // if (this.refreshInterval) clearInterval(this.refreshInterval);
    return true
  },
  methods: {
    refreshConfig() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}accounts`)
        .then((response) => {
          this.configItems = response.body;
        })
        .catch(console.error);
    },
  },
};
</script>
